import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="horizon-walker"></a><h3>HORIZON WALKER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG190_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d8.</p>
    <a id="horizon-walker-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a horizon walker, a
character must fulfill all the following criteria.</p>
    <p><b>Skills</b>: Knowledge (geography) 8 ranks.</p>
    <p><b>Feats</b>: Endurance.</p>
    <h6>Class Skills</h6>
    <p className="initial">The horizon walker&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#balance">Balance</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#climb">Climb</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#handle-animal">Handle
Animal</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(geography) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#move-silently">Move
Silently</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#ride">Ride</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#speak-language">Speak Language</a> (none), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
(Wis), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#survival">Survival</a> (Wis). </p>
    <p><b>Skill Points at Each Level</b>: 4 + Int modifier.</p>
    <a id="table-the-horizon-walker"></a><p><b>Table: The Horizon Walker</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "8%"
          }}>Level</th>
          <th style={{
            "width": "15%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "8%"
          }}>Fort
Save</th>
          <th style={{
            "width": "8%"
          }}>Ref
Save</th>
          <th style={{
            "width": "8%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+0</td>
          <td>+0</td>
          <td>Terrain mastery</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+0</td>
          <td>+0</td>
          <td>Terrain mastery</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>Terrain mastery</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Terrain mastery</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Terrain mastery</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>Planar terrain mastery</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>Planar terrain mastery</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
          <td>Planar terrain mastery</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
          <td>Planar terrain mastery</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+10</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">Planar terrain mastery</td>
        </tr>
      </tbody>
    </table>
    <a id="horizon-walker-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are features of the
horizon walker prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Horizon walkers gain no
proficiency with any weapon or armor.</p>
    <a id="horizon-walker-terrain-mastery"></a><p><b>Terrain Mastery</b>: At each level, the Horizon Walker adds
a new terrain environment to their repertoire from those given below.
Terrain mastery gives a horizon walker a bonus on checks involving a
skill useful in that terrain, or some other appropriate benefit. A
horizon walker also knows how to fight dangerous creatures typically
found in that terrain, gaining a +1 insight bonus on attack rolls and
damage rolls against creatures with that terrain mentioned in the
Environment entry of their descriptions. The horizon walker only gains
the bonus if the creature description specifically lists the terrain
type.</p>
    <p>Horizon walkers take their terrain mastery with them wherever
they go. They retain their terrain mastery bonuses on skill checks,
attack rolls, and damage rolls whether they&#8217;re actually in the relevant
terrain or not.</p>
    <a id="horizon-walker-planar-terrain-mastery"></a><p><b>Planar Terrain Mastery</b>: Planar terrain mastery
functions just like terrain mastery, except that the horizon walker can
choose one of the planar categories at each level. The horizon walker
can take a non-planar terrain type instead, if she wishes.</p>
    <a id="horizon-walker-terrain-mastery-benefits"></a><h6>Terrain Mastery Benefits</h6>
    <a id="horizon-walker-aquatic"></a><p><b>Aquatic</b>: You gain a +4 competence bonus on Swim checks,
or a +10-foot bonus to your swim speed if you have one. You gain a +1
insight bonus on attack and damage rolls against aquatic creatures.</p>
    <a id="horizon-walker-desert"></a><p><b>Desert</b>: You resist effects that tire you. You are
immune to fatigue, and anything that would cause you to become
exhausted makes you fatigued instead. You gain a +1 insight bonus on
attack and damage rolls against desert creatures.</p>
    <a id="horizon-walker-forest"></a><p><b>Forest</b>: You have a +4 competence bonus on Hide checks.
You gain a +1 insight bonus on attack and damage rolls against forest
creatures.</p>
    <a id="horizon-walker-hills"></a><p><b>Hills</b>: You gain a +4 competence bonus on Listen checks.
You gain a +1 insight bonus on attack and damage rolls against hills
creatures.</p>
    <a id="horizon-walker-marsh"></a><p><b>Marsh</b>: You have a +4 competence bonus on Move Silently
checks. You gain a +1 insight bonus on attack and damage rolls against
marsh creatures.</p>
    <a id="horizon-walker-mountains"></a><p><b>Mountains</b>: You gain a +4 competence bonus on Climb
checks, or a +10- foot bonus to your climb speed if you have one. You
gain a +1 insight bonus on attack and damage rolls against mountain
creatures.</p>
    <a id="horizon-walker-plains"></a><p><b>Plains</b>: You have a +4 competence bonus on Spot checks.
You gain a +1 insight bonus on attack and damage rolls against plains
creatures.</p>
    <a id="horizon-walker-undeground"></a><p><b>Underground</b>: You have 60-foot darkvision, or 120-foot
darkvision if you already had darkvision from another source. You gain
a +1 insight bonus on attack and damage rolls against underground
creatures.</p>
    <a id="horizon-walker-fiery-planar"></a><p><b>Fiery (Planar)</b>: This kind of planar terrain mastery
provides you with resistance to fire 20. You gain a +1 insight bonus on
attack and damage rolls against outsiders and elementals with the fire
subtype.</p>
    <a id="horizon-walker-weightless-planar"></a><p><b>Weightless (Planar)</b>: You gain a +30-foot bonus to your
fly speed on planes with no gravity or subjective gravity. You gain a
+1 insight on attack and damage rolls against creatures native to the
Astral Plane, the Elemental Plane of Air, and the Ethereal Plane.</p>
    <a id="horizon-walker-cold-planar"></a><p><b>Cold (Planar)</b>: This kind of planar terrain mastery
provides you with resistance to cold 20. You gain a +1 insight bonus on
attack and damage rolls against outsiders and elementals with the cold
subtype.</p>
    <a id="horizon-walker-shifting-planar"></a><p><b>Shifting (Planar)</b>: You instinctively anticipate shifts
in the reality of the plane that bring you closer to your destination,
giving you the spell-like ability to use dimension door (as the spell
cast at your character level) once every 1d4 rounds. You gain a +1
insight bonus on attack and damage rolls against outsiders and
elementals native to a shifting plane.</p>
    <a id="horizon-walker-aligned-planar"></a><p><b>Aligned (Planar)</b>: You have the instinctive ability to
mimic the dominant alignment of the plane. You incur none of the
penalties for having an alignment at odds with that of the plane, and
spells and abilities that harm those of the opposite alignment don&#8217;t
affect you. You have the dominant alignment of the plane with regard to
magic, but your behavior and any alignment-related Class Features you
have are unaffected.</p>
    <a id="horizon-walker-cavernous-planar"></a><p><b>Cavernous (Planar)</b>: You gain tremorsense with a 30-foot
range.</p>
    <a id="horizon-walker-other-planar"></a><p><b>Other (Planar)</b>: If other planes are in use additional
Planar Terrains can be created.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      